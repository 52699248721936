'use strict';

/**
 * Bind functions initially and on resize
 */
function resizeFunctions() {
  Breakpoint.refreshValue();
}

window.onresize = resizeFunctions;
window.onload = resizeFunctions;


$(function() {
  validateContactForm();
});


// Validate form
function validateContactForm() {
  // Get the form.
  var form = $('.js-form');

  $(form).submit(function(event) {
    // Stop the browser from submitting the form.
    event.preventDefault();

    $('input', $(form)).removeClass('error');
    $('textarea', $(form)).removeClass('error');

    // Get the messages div.
    var formMessages = $('.js-messages');
    var formData = $(form).serialize();

    // Submit the form using AJAX.
    $.ajax({
        type: 'POST',
        url: $(form).attr('action'),
        data: formData
    })
    .done(function(response) {
        // Make sure that the formMessages div has the 'success' class.
        $(formMessages).removeClass('error');
        $(formMessages).addClass('success');
    })
    .success(function(response) {
      // Clear the form.
      $(form).hide();
      document.querySelector('.js-form').reset();

      console.log(response);

      $('.form--success').show();
      $('.form--error').hide();
    })
    .fail(function(data) {

      checkFields($(form));

      // Make sure that the formMessages div has the 'error' class.
      $(formMessages).removeClass('success');
      $(formMessages).addClass('error');

      if (data.responseText === 'show_error') {
        $('.form--error').show();
      }
    });
  });

  // Adding invisble field to prevent spam
  $hiddenField = $('<input/>').attr('type', 'hidden')
    .attr('name', 'nospam')
    .val('i_am_no_robot');

  $('.js-form').prepend($hiddenField);
}

// Add error class to fields
function checkFields($form) {
  var $name = $('#name'),
      $email = $('#email'),
      $message = $('#message'),
      name = $name.val(),
      email = $email.val(),
      message = $message.val();

  // if (name === '') {
  //   $name.addClass('error');
  // }

  if (email === '') {
    $email.addClass('error');
  }

  // if (message === '') {
  //   $message.addClass('error');
  // }
}
