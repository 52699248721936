/**
 * Menu functions
 *
 */
'use strict';

(function(window, document, undefined) {
  function createSubmenuArrow(type, key) {
    var $label = document.createElement('label'),
        $checkbox = document.createElement('input'),
        $html;

    switch (type) {
      case 'label':
        $html = document.createElement('label');
        $html.setAttribute('class', 'js-submenu menu__arrow');
        $html.setAttribute('for', 'submenu_' + key);
        break;
      case 'checkbox':
        $html = document.createElement('input');
        $html.setAttribute('type', 'checkbox');
        $html.setAttribute('id', 'submenu_' + key);
        break;
    }


    //$label.appendChild($html);

    return $html;
  }

  function appendSubmenuArrow(element) {
    var $menu = document.querySelector(element),
        $submenu = $menu.querySelectorAll('.has_children'),
        toggleLabel,
        toggleCheckbox,
        key;

    if ($submenu) {
      for (var i = 0; i < $submenu.length; i++) {
        key = Math.round(Math.random()*10) + 1;

        toggleLabel = createSubmenuArrow('label', key);
        toggleCheckbox = createSubmenuArrow('checkbox', key);
        $submenu[i].insertBefore(toggleLabel, $submenu[i].firstChild);
        $submenu[i].insertBefore(toggleCheckbox, $submenu[i].firstChild);
      }
    }
  }

  function init(element) {
    Breakpoint.refreshValue();
    
    if (Breakpoint.value !== 'large') {
      appendSubmenuArrow(element);
    }
  }



  init('.js-menu'); // animation in non-IE only
}(window, document));
