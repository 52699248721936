/**
 * Import breakpoints from CSS
 *
 * Source: https://www.lullabot.com/blog/article/importing-css-breakpoints-javascript
 *
 * @type {Object}
 */
'use strict';

var Breakpoint = {};

/**
 * Gets the value from the <body>
 *
 * @this {Breakpoint}
 * @return {Object|String} returns the value as a string
 */
Breakpoint.refreshValue = function () {
  this.value = window.getComputedStyle(document.querySelector('body'), ':before')
                      .getPropertyValue('content').replace(/['"]+/g, '');
};
